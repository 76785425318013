import React, { useState } from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import { Helmet } from 'react-helmet';
import FsLightbox from 'fslightbox-react';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Title from '../../components/Title';
import Waves from '../../components/Waves';
import Next from '../../components/Next';
import Questions from '../../components/Questions';
import LicensesItem from '../../components/LicensesItem';
import introSVG from './licenses-intro.svg';
import introMobileSVG from './licenses-intro-mobile.svg';
import json from '../../data.json';
import './Licenses.styl';

const b = block('licenses');
const licenses = json.licenses;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function LicensesPage(props) {
  const [image, setImage] = useState('');
  const [toggler, setToggler] = useState(false);

  const changeImage = (src) => {
    setImage(src);
    setToggler(!toggler);
  };

  return (
    <Layout className={b()} title={'Лицензии'}>
      <Helmet>
        <title>{licenses.pageTitle}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
        <div className={b('intro')}>
          <Breadcrumbs data={licenses.breadcrumbs} />
          <Title>{licenses.title}</Title>
          <Default>
            <IntroSVG />
          </Default>
          <Mobile>
            <IntroMobileSVG />
          </Mobile>
        </div>
        <Waves
          id='1'
          colorUp={'#ffffff'}
          colorDown={'#f5f5f5'}
          composite1={'screen'}
          composite2={'darken'}
        />
        <div className={b('page').mix('text')}>
          {licenses.items.map(item => (
            <LicensesItem
              key={item.id}
              data={item}
              updateData={(src) => changeImage(src)}
            />
          ))}
          <FsLightbox
            toggler={toggler}
            sources={[image]}
          />
        </div>
        <Waves
          id='2'
          colorUp={'#f5f5f5'}
          colorDown={'#ffffff'}
          composite1={'screen'}
          composite2={'source-atop'}
        />
        <Next
          name={licenses.nextName}
          url={licenses.nextUrl}
        />
        <Questions />
      </div>
    </Layout>
  )
};

const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

export default LicensesPage;
