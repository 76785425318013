import React from 'react';
import { NavLink } from 'react-router-dom';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import arrowSVG from './arrow.svg';
import './Next.styl';

const b = block('next');

const Next = (props) => {
  return (
    <div className={b()}>
      <NavLink to={props.url}>
        <span>{props.name}</span>
        <ArrowSVG />
      </NavLink>
    </div>
  );
};

const ArrowSVG = () => (
  <ReactVivus
    id="arrow-svg"
    className="arrow-svg"
    option={{
      file: arrowSVG,
      duration: 10,
      type: 'sync'
    }}
  />
);

export default Next;
