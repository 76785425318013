import React, { Component } from 'react';
import block from 'bem-cn';
import ym from 'react-yandex-metrika';
import './Phone.styl';

const b = block('phone');

class Phone extends Component {

  replacePhone(number) {
    let phone = number.replace(/[ ()-]/g, '');
    return phone;
  }

  onChangePhone() {
    ym('reachGoal', 'phone');
  }

  onChangeMail() {
    ym('reachGoal', 'mail');
  }

  render() {
    const { data } = this.props;

    return (
      <div className={b({ type: this.props.mods })}>
        <div className={b('city')}>{data.city}</div>

        <div className={b('number')}>
          <span className={b('label')}>Рабочий телефон:</span>
          <a onClick={this.onChangePhone.bind(this)} href={`tel:${this.replacePhone(data.phone)}`}>{data.phone}</a>
        </div>

        <div className={b('email')}>
          <span className={b('label')}>Электронная почта:</span>
          <a onClick={this.onChangeMail.bind(this)} href={`mailto:${data.email}`}>{data.email}</a>
        </div>
      </div>
    )
  }
}

export default Phone;
