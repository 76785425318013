import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import upSVG from './up.svg';
import './Up.styl';

const b = block('up');

class Up extends Component {
  scrollToUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render () {
    return (
      <div className={b()}>
        <Link to={this.props.url} className={b('link')} onClick={this.scrollToUp.bind(this)}>
          <span>
            <span>{this.props.name}</span>
            <UpSVG />
          </span>
        </Link>
      </div>
    );
  }
};

const UpSVG = () => (
  <ReactVivus
    id="up-svg"
    className="up-svg"
    option={{
      file: upSVG,
      type: 'delayed'
    }}
  />
);

export default Up;
