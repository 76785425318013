import React from 'react';
import block from 'bem-cn';
import './ReviewsItem.styl';

const b = block('reviewsitem');

function createMarkup(data) {
  return { __html: data }
}

function ReviewsItem(props) {
  const { name, description, image, photo } = props.data;

  return (
    <div className={b()}>
      <div className={b('content')}>
        <p className={b('desc')} dangerouslySetInnerHTML={createMarkup(description)} />
        <p className={b('name')} dangerouslySetInnerHTML={createMarkup(name)} />
      </div>
      <div className={b('image')} onClick={() => {props.updateData(process.env.PUBLIC_URL + photo)}}>
        <img src={process.env.PUBLIC_URL + image} alt="" />
      </div>
    </div>
  )
}

export default ReviewsItem;
