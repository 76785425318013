import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import block from 'bem-cn';
import './Menu.styl';

const b = block('menu');

class Menu extends Component {
  render() {
    const { items, mods } = this.props;

    return (
      <ul className={b({ type: mods })}>
        {
          items.map(item => (
            <li
              key={item.id}
              className={b('item')}
            >
              <NavLink to={item.url}>{item.name}</NavLink>
            </li>
          ))
        }
      </ul>
    )
  }
}

export default Menu;
