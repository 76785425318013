import React from 'react';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import './Card.styl';

const b = block('card');

const Desktop = props => <Responsive {...props} minWidth={1024} />;
const Tablet = props => (<Responsive {...props} minWidth={768} maxWidth={1023} />);
const Mobile = props => <Responsive {...props} maxWidth={767} />;

function createMarkup(data) {
  return { __html: data }
};

function Card(props) {
  return (
    props.url ?
      <Link to={props.url} className={b({ type: props.mods })}>
        <div className={b('icon')}>
          <Desktop>
              {props.noBig ? <img src={process.env.PUBLIC_URL + props.iconMobile} alt=""/> : <img src={process.env.PUBLIC_URL + props.icon} alt=""/>}
          </Desktop>

          <Tablet>
              {props.iconMobile ? <img src={process.env.PUBLIC_URL + props.iconMobile} alt=""/> : ''}
          </Tablet>

          <Mobile>
              {props.iconMobile ? <img src={process.env.PUBLIC_URL + props.iconMobile} alt=""/> : ''}
          </Mobile>
        </div>

        <div className={b('link')}>
          <p dangerouslySetInnerHTML={createMarkup(props.name)} />
        </div>
      </Link>
    :
      <div className={b({ type: props.mods })}>
        <div className={b('icon')}>
          <Desktop>
              {props.icon ? <img src={process.env.PUBLIC_URL + props.icon} alt=""/> : ''}
          </Desktop>

          <Tablet>
              {props.iconMobile ? <img src={process.env.PUBLIC_URL + props.iconMobile} alt=""/> : ''}
          </Tablet>

          <Mobile>
              {props.iconMobile ? <img src={process.env.PUBLIC_URL + props.iconMobile} alt=""/> : ''}
          </Mobile>
        </div>

        <div className={b('text')}>
          <p dangerouslySetInnerHTML={createMarkup(props.name)} />
        </div>
      </div>
  )
}

export default Card;
