import React from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout';
import Intro from './components/Intro';
import Waves from './components/Waves';
import Services from './components/Services';
import Advantages from './components/Advantages';
import Customers from './components/Customers';
import json from './data.json';

const b = block('app');
const index = json.index;
const services = json.services;
const advantages = json.advantages;
const customers = json.customers;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

const App = () => {
  return (
    <Layout className={b()} title={'Главная'}>
      <Helmet>
        <title>{index.pageTitle}</title>
      </Helmet>

      <Intro title={index.title} />
      <Waves
        id="1"
        colorUp={'#ffffff'}
        colorDown={'#4e5aa4'}
        composite1={'screen'}
        composite2={'darken'}
      />
      <Services
        color="white"
        title={services.mainTitle}
        data={services.items.slice(0, 3)}
        mods="main"
        more={false}
      />
      <Waves
        id="2"
        colorUp={'#4e5aa4'}
        colorDown={'#36385d'}
        composite1={'screen'}
        composite2={'darken'}
      />
      <Services
        color="white"
        title={services.otherTitle}
        data={services.items.slice(3, 7)}
        mods="other"
        more={true}
      />
      <Waves
        id="3"
        colorUp={'#1d1a36'}
        colorDown={'#ffffff'}
        composite1={'screen'}
        composite2={'source-atop'}
      />
      <Advantages
        color="black"
        title={advantages.title}
        data={advantages.items}
      />
      <Waves
        id="4"
        colorUp={'#ffffff'}
        colorDown={'#f5f5f5'}
        composite1={'screen'}
        composite2={'darken'}
      />
      <Default>
        <Customers
          theme="main"
          color="black"
          title={customers.title}
          data={customers.items.slice(0, 6)}
          link={false}
          more={true}
        />
      </Default>
      <Mobile>
        <Customers
          theme="main"
          color="black"
          title={customers.title}
          data={customers.items.slice(0, 3)}
          link={false}
          more={true}
        />
      </Mobile>
    </Layout>
  );
};

export default App;
