import React from 'react';
import block from 'bem-cn';
import burger from './burger.svg';
import './Burger.styl';

const b = block('burger');

function Burger(props) {
  return (
    <div className={props.open ? b({ active: true }) : b()} onClick={props.onChange} >
      <img src={burger} alt="burger" />
    </div>
  );
}

export default Burger;
