import React from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import Logo from '../Logo';
import Menu from '../Menu';
import Phone from '../Phone';
import Burger from '../Burger';
import './Header.styl';

const b = block('header');

const Desktop = props => <Responsive {...props} minWidth={1024} />;
const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

const Header = (props) => {
  const { menu, contacts, burger, header } = props;

  return (
    <div className={header ? b({ fixed: true }) : b()}>
      <Logo />

      <Default>
        <Menu items={menu} mods='header' />
      </Default>

      <Desktop>
        <Phone data={contacts} mods='header' />
      </Desktop>

      <Mobile>
        <Burger open={burger} onChange={props.onChange} />
      </Mobile>
    </div>
  )
}

export default Header;
