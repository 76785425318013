import React from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import Title from '../Title';
import introSVG from './intro.svg';
import introMobileSVG from './intro-mobile.svg';
import './Intro.styl';

const b = block('intro');

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function Intro(props) {
  return (
    <div className={b()}>
      <Title>{props.title}</Title>

      <Default>
        <IntroSVG />
      </Default>

      <Mobile>
        <IntroMobileSVG />
      </Mobile>
    </div>
  )
}

const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed'
    }}
  />
);

export default Intro;
