import React, { useState } from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Title from '../../components/Title';
import Waves from '../../components/Waves';
import Next from '../../components/Next';
import Questions from '../../components/Questions';
import Request from '../../components/Request';
import Modal from '../../components/Modal';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import introSVG from './contacts-intro.svg';
import introMobileSVG from './contacts-intro-mobile.svg';
import json from '../../data.json';
import './Contacts.styl';

const b = block('contacts');
const contacts = json.contacts;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function ContactsPage() {

  const [showModalSuccess, setsHowModalSuccess] = useState(false);

  const toggleModalSuccess = () => {
    setsHowModalSuccess(!showModalSuccess);
  };

  const handlerShowModalSuccess = () => {
    setsHowModalSuccess(!showModalSuccess);
  }

  return (
    <Layout className={b()} title={'Контакты'}>
      <Helmet>
        <title>{contacts.pageTitle}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
        <div className={b('intro')}>
          <Breadcrumbs data={contacts.breadcrumbs} />
          <Title>{contacts.title}</Title>
          <Default>
            <IntroSVG />
          </Default>
          <Mobile>
            <IntroMobileSVG />
          </Mobile>
        </div>
        <Waves
          id='1'
          colorUp={'#ffffff'}
          colorDown={'#f5f5f5'}
          composite1={'screen'}
          composite2={'darken'}
        />

        <div className={b('page').mix('text')}>
          <h3>Группа компаний «Сфера»</h3>

          <div className={b('text')}>
            <p><strong>Адрес:</strong> 127566, г. Москва, Высоковольтный пр., д. 1 корп. 6</p>
            <p><strong>Телефон:</strong> +7 (499) 290-39-79</p>
            <p><strong>Почта:</strong> info@gc-sfera.ru</p>
            <p><strong>График работы:</strong> Пн-Пт: 09:00 – 18:00, Сб-Вс: Выходной</p>
          </div>

          <div className={b('map')}>
            <YMaps>
              <Map
                defaultState={{
                  center: [55.875213, 37.590686],
                  zoom: 16,
                  controls: ['zoomControl']
                }}
                width={'100%'}
                height={'100%'}
                modules={['control.ZoomControl']}
                instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
              >
                <Placemark
                  geometry={[55.875213, 37.590686]}
                  properties={{balloonContent: 'Группа компаний «Сфера» <br> 127566, г. Москва, Высоковольтный пр., д. 1 корп. 6'}}
                  modules={['geoObject.addon.balloon']}
                />
              </Map>
            </YMaps>
          </div>

          <h3>Оставьте заявку</h3>

          <div className={b('request')}>
            <Request
              showSuccess={handlerShowModalSuccess}
            />

            <Modal
              title='Отправка'
              open={showModalSuccess}
              closeModal={toggleModalSuccess}
            >
              Сообщение успешно отправлено.
            </Modal>
          </div>
        </div>

        <Waves
          id='2'
          colorUp={'#f5f5f5'}
          colorDown={'#ffffff'}
          composite1={'screen'}
          composite2={'source-atop'}
        />
        <Next
          name={contacts.nextName}
          url={contacts.nextUrl}
        />
        <Questions />
      </div>
    </Layout>
  )
}

const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

export default ContactsPage;
