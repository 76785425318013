import React from 'react';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import { Helmet } from 'react-helmet';
import Responsive from 'react-responsive';
import Layout from '../../components/Layout';
import introSVG from './none.svg';
import introMobileSVG from './none-mobile.svg';
import json from '../../data.json';
import './None.styl';

const b = block('none');
const no = json.no;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function None() {
  return (
    <Layout className={b()} title={'404 Not Found!'}>
      <Helmet>
        <title>{no.pageTitle}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
        <div className={b('image')}>
          <Default>
            <IntroSVG />
          </Default>
          <Mobile>
            <IntroMobileSVG />
          </Mobile>
        </div>
      </div>

    </Layout>
  )
}

const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

export default None;
