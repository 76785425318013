import React from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Title from '../../components/Title';
import Waves from '../../components/Waves';
import Next from '../../components/Next';
import Questions from '../../components/Questions';
import CustomersItem from '../../components/CustomersItem';
import introSVG from './customers-intro.svg';
import introMobileSVG from './customers-intro-mobile.svg';
import json from '../../data.json';
import './Customers.styl';

const b = block('customers');
const customers = json.customers;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function CustomersPage() {
  return (
    <Layout className={b()} title={'Клиенты'}>
      <Helmet>
        <title>{customers.pageTitle}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
        <div className={b('intro')}>
          <Breadcrumbs data={customers.breadcrumbs} />
          <Title>{customers.title}</Title>
          <Default>
            <IntroSVG />
          </Default>
          <Mobile>
            <IntroMobileSVG />
          </Mobile>
        </div>
        <Waves
          id='1'
          colorUp={'#ffffff'}
          colorDown={'#f5f5f5'}
          composite1={'screen'}
          composite2={'darken'}
        />
        <div className={b('page').mix('text')}>
          {customers.items.map(item => (
            <CustomersItem
              key={item.id}
              data={item}
              link={true}
              theme="inside"
            />
          ))}
        </div>
        <Waves
          id='2'
          colorUp={'#f5f5f5'}
          colorDown={'#ffffff'}
          composite1={'screen'}
          composite2={'source-atop'}
        />
        <Next
          name={customers.nextName}
          url={customers.nextUrl}
        />
        <Questions />
      </div>
    </Layout>
  )
}

const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

export default CustomersPage;
