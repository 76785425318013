import React from 'react';
import block from 'bem-cn';
import { useForm } from 'react-hook-form';
import ym from 'react-yandex-metrika';
import axios from 'axios';
import './Request.styl';

const b = block('request');

function Request(props) {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    ym('reachGoal', 'send');
    props.showSuccess(true);

    axios.post('https://sfera-backend.now.sh/api/v1', data)
    // axios.post('http://localhost:4000/api/v1', data)
    .then(response => {
      console.log(response.date);
      console.log('Message sent..');
    })
    .catch(error => {
      console.log(error);
      console.log('Message not sent..');
    });
  }

  return (
    <form className={b()} onSubmit={handleSubmit(onSubmit)}>
      <div className={b('form-item')}>
        <input
          name="name"
          type="text"
          placeholder="Ваше имя"
          ref={register({ required: true })}
        />
        <span className={b('error')}>{errors.name && 'Введите имя.'}</span>
      </div>

      <div className={b('form-item')}>
        <input
          name="email"
          type="text"
          placeholder="Электронная почта"
          ref={register({
            required: 'Введите электронную почту.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Неверный адрес электронной почты."
            }
          })}
        />
        <span className={b('error')}>{errors.email && errors.email.message}</span>
      </div>

      <div className={b('form-item')}>
        <textarea
          name="message"
          type="text"
          placeholder="Сообщение"
          ref={register({ required: true })}
        />
        <span className={b('error')}>{errors.message && 'Заполните поле сообщение.'}</span>
      </div>

      <button
        type="submit"
        className={b('submit')}
      >Отправить заявку</button>

      <div className={b('policy')}>Нажимая на кнопку, вы даете<br /><a href='/policy.pdf' target='_blank'>согласие на обработку своих персональных данных.</a></div>
    </form>
  );
};

export default Request;
