import React, { Component } from 'react';
import block from 'bem-cn';
import ym from 'react-yandex-metrika';
import More from '../More';
import Modal from '../Modal';
import Request from '../Request';
import './Questions.styl';

const b = block('questions');

class Questions extends Component {
  state = {
    showModal: false,
    showModalSuccess: false
  }

  toggleModal() {
    if (!this.state.showModal) {
      ym('reachGoal', 'popup');
    }

    this.setState({
      showModal: !this.state.showModal
    });
  }

  toggleModalSuccess() {
    this.setState({
      showModalSuccess: !this.state.showModalSuccess
    });
  }

  showModalSuccess = (value) => {
    this.setState({
      showModal: !this.state.showModal,
      showModalSuccess: !this.state.showModalSuccess
    });
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('text')}>
          <div className={b('title')}>
            Остались вопросы?
          </div>
          <div className={b('desc')}>
            Оставляйте нам заявку, спрашивайте что вас интересует, мы вам напишем или перезвоним и ответим на все ваши вопросы
          </div>
        </div>
        <div className={b('button')}>
          <More
            name='Оставить заявку'
            open={this.state.showModal}
            onChange={this.toggleModal.bind(this)}
          />
        </div>
        <Modal
          title='Оставьте заявку'
          open={this.state.showModal}
          closeModal={this.toggleModal.bind(this)}
        >
          <Request
            showModal={this.state.showModal}
            showSuccess={this.showModalSuccess}
          />
        </Modal>
        <Modal
          title='Отправка'
          open={this.state.showModalSuccess}
          closeModal={this.toggleModalSuccess.bind(this)}
        >
          Сообщение успешно отправлено.
        </Modal>
      </div>
    );
  }
};

export default Questions;
