import React from 'react';
import block from 'bem-cn';
import close from './close.svg';
import './Popup.styl';

const b = block('popup');

function Popup(props) {
  return (
    <div className={props.open ? b({active: true}) : b()}>

      {props.children}

      <div className={b('close')} onClick={props.onChange}>
        <img src={close} alt="close" />
      </div>
    </div>
  );
}

export default Popup;
