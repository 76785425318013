import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import logo from './logo.svg';
import logoMobile from './logo-mobile.svg';
import logoWhite from './logo-white.svg';
import './Logo.styl';

const b = block('logo');


const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function Logo(props) {
  let location = useLocation();

  if (location.pathname === '/') {
    if (props.mods === 'white') {
      return (
        <div className={b({ type: props.mods })}>
          <img src={logoWhite} alt="logo" />
        </div>
      )
    } else {
      return (
        <div className={b({ type: props.mods })}>
          <Default>
            <img src={logo} alt="logo" />
          </Default>

          <Mobile>
            <img src={logoMobile} alt="logo" />
          </Mobile>
        </div>
      )
    }
  } else {
    if (props.mods === 'white') {
      return (
        <Link to="/" className={b({ type: props.mods })}>
          <img src={logoWhite} alt="logo" />
        </Link>
      )
    } else {
      return (
        <Link to="/" className={b({ type: props.mods })}>
          <Default>
            <img src={logo} alt="logo" />
          </Default>

          <Mobile>
            <img src={logoMobile} alt="logo" />
          </Mobile>
        </Link>
      )
    }
  }
}

export default Logo;
