import React from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Title from '../../components/Title';
import Waves from '../../components/Waves';
import Next from '../../components/Next';
import Questions from '../../components/Questions';
import introSVG from './about-intro.svg';
import introMobileSVG from './about-intro-mobile.svg';
import aboutPhoto1 from './photo-1.jpg';
import aboutPhoto2 from './photo-2.jpg';
import aboutPhoto3 from './photo-3.jpg';
import json from '../../data.json';
import './About.styl';

const b = block('about');
const about = json.about;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function AboutPage() {
  function createMarkup(data) {
    return { __html: data }
  }

  return (
    <Layout className={b()} title={'О нас'}>
      <Helmet>
        <title>{about.pageTitle}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
        <div className={b('intro')}>
          <Breadcrumbs data={about.breadcrumbs} />
          <Title>{about.title}</Title>
          <Default>
            <IntroSVG />
          </Default>
          <Mobile>
            <IntroMobileSVG />
          </Mobile>
        </div>
        <Waves
          id='1'
          colorUp={'#ffffff'}
          colorDown={'#f5f5f5'}
          composite1={'screen'}
          composite2={'darken'}
        />

        <div className={b('page').mix('text')}>
          <div className={b('container')}>
            <div className={b('item')} dangerouslySetInnerHTML={createMarkup(about.content.text1)} />
            <div className={b('item')}><img src={aboutPhoto1} alt="" /></div>
            <div className={b('item')} dangerouslySetInnerHTML={createMarkup(about.content.text3)} />
            <div className={b('item')}><img src={aboutPhoto2} alt="" /></div>
            <div className={b('item')} dangerouslySetInnerHTML={createMarkup(about.content.text5)} />
            <div className={b('item')}><img src={aboutPhoto3} alt="" /></div>
            <div className={b('item')} dangerouslySetInnerHTML={createMarkup(about.content.text7)} />
          </div>
        </div>

        <Waves
          id='2'
          colorUp={'#f5f5f5'}
          colorDown={'#ffffff'}
          composite1={'screen'}
          composite2={'source-atop'}
        />
        <Next
          name={about.nextName}
          url={about.nextUrl}
        />
        <Questions />
      </div>
    </Layout>
  )
}

const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

export default AboutPage;
