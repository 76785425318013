import React from 'react';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import ReactVivus from 'react-vivus';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Title from '../../components/Title';
import Waves from '../../components/Waves';
import Next from '../../components/Next';
import Questions from '../../components/Questions';
import Card from '../../components/Card';
import introSVG from './services-intro.svg';
import introMobileSVG from './services-intro-mobile.svg';
import json from '../../data.json';
import './Services.styl';

const b = block('services');
const services = json.services;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

function ServicesPage() {
  return (
    <Layout className={b()} title={'Услуги'}>
      <Helmet>
        <title>{services.pageTitle}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
          <div className={b('intro')}>
            <Breadcrumbs data={services.breadcrumbs} />
            <Title>{services.title}</Title>
            <Default>
              <IntroSVG />
            </Default>
            <Mobile>
              <IntroMobileSVG />
            </Mobile>
          </div>
          <Waves
            id='1'
            colorUp={'#ffffff'}
            colorDown={'#f5f5f5'}
            composite1={'screen'}
            composite2={'darken'}
          />

        <div className={b('page').mix('text')}>
          <h3>Противопожарная защита</h3>
            <div className={b('list')}>
              {services.items.slice(0, 3).map(item => (
                <div key={item.id} className={b('item')}>
                  <Card
                    url={item.url}
                    name={item.name}
                    icon={item.icon}
                    iconMobile={item.iconMobile}
                    mods={'services-min'}
                    noBig={true}
                  />
                </div>
              ))}
            </div>
          <h3>Другие услуги</h3>
            <div className={b('list')}>
              {services.items.slice(3, 11).map(item => (
                <div key={item.id} className={b('item')}>
                  <Card
                    url={item.url}
                    name={item.name}
                    icon={item.icon}
                    iconMobile={item.iconMobile}
                    mods={'services-min'}
                    noBig={true}
                  />
                </div>
              ))}
            </div>
        </div>

        <Waves
          id='2'
          colorUp={'#f5f5f5'}
          colorDown={'#ffffff'}
          composite1={'screen'}
          composite2={'source-atop'}
        />
        <Next
          name={services.nextName}
          url={services.nextUrl}
        />
        <Questions />
      </div>
    </Layout>
  )

}
const IntroSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

const IntroMobileSVG = () => (
  <ReactVivus
    id="intro-svg"
    className="intro-svg"
    option={{
      file: introMobileSVG,
      type: 'delayed',
      start: 'autostart'
    }}
  />
);

export default ServicesPage;
