import React, { Component } from 'react';
import block from 'bem-cn';
import SimplexNoise from 'simplex-noise';
import './Waves.styl';

const b = block('waves');

class Waves extends Component {
  componentDidMount(props) {
    if (window) {

      // const gui = new dat.GUI(),
      const guiSet = {
        frequency: 75,
        reset: () => {
          $.reset();
        }
      };

      // gui.add(guiSet, 'frequency').min(10).max(100);
      // gui.add(guiSet, 'reset');

      const $ = {};

      /*========================================
      Initialize
      ========================================*/

      $.init = () => {
        $.c = document.querySelector('canvas');
        $.c = document.querySelector(".waves__" + this.props.id);
        $.ctx = $.c.getContext('2d');
        $.simplex = new SimplexNoise();
        $.events();
        $.reset();
        $.loop();
      };

      /*========================================
      Reset
      ========================================*/

      $.reset = () => {
        $.w = window.innerWidth;
        $.w = document.documentElement.clientWidth;
        $.h = 120;
        $.cx = $.w / 2;
        $.cy = $.h / 2;
        $.c.width = $.w;
        $.c.height = $.h;
        $.count = Math.floor($.w / guiSet.frequency); // Wave frequency
        $.xoff = 0;
        $.xinc = 0.05;
        $.yoff = 0;
        $.yinc = 0.001; // Speed
        $.goff = 0;
        $.ginc = 0;
        $.y = $.h * 0.65;
        $.length = $.w + 0;
        $.amp = 25; // Wave height
      };

      /*========================================
      Event
      ========================================*/

      $.events = () => {
        window.addEventListener('resize', $.reset.bind(undefined));
      };

      /*========================================
      Wave
      ========================================*/

      $.wave = (color, amp, height, comp) => {
        $.ctx.beginPath();

        const sway = $.simplex.noise2D($.goff, 0) * amp;

        for (let i = 0; i <= $.count; i++) {
          $.xoff += $.xinc;

          const x = $.cx - $.length / 2 + $.length / $.count * i,
                y = height + $.simplex.noise2D($.xoff, $.yoff) * amp + sway;

          $.ctx[i === 0 ? 'moveTo' : 'lineTo'](x, y);
        }

        $.ctx.lineTo($.w, -$.h); // -$.h - Vertically reflection
        $.ctx.lineTo(0, -$.h); // -$.h - Vertically reflection
        $.ctx.closePath();
        $.ctx.fillStyle = color;

        if (comp) {
          $.ctx.globalCompositeOperation = comp;
        }

        $.ctx.fill();
      };

      /*========================================
      Loop
      ========================================*/

      $.loop = (props) => {
        requestAnimationFrame($.loop);

        $.ctx.clearRect(0, 0, $.w, $.h);
        $.xoff = 0;

        $.ctx.fillStyle = this.props.colorDown;
        $.ctx.fillRect(0, 0, $.w, $.h);

        $.wave(this.props.colorUp, 50, $.h * .5, this.props.composite1);


        $.ctx.globalCompositeOperation = this.props.composite2;
        $.ctx.fillRect(0, 0, $.w, $.h);

        $.yoff += $.yinc;
        $.goff += $.ginc;
      };

      /*========================================
      Start
      ========================================*/

      $.init();
    } else {
     // код, который выполнится только на сервере
    }
  }

  render(props) {
    return (
      <div className={b()}>
        <canvas className={b(this.props.id)}></canvas>
      </div>
    )
  }
}

export default Waves;
