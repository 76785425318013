import React, { useState } from 'react';
import block from 'bem-cn';
import { useCookies } from 'react-cookie';
import { ReactComponent as CloseSVG } from './close.svg';
import './Cookie.styl';

const b = block('cookie');

const Cookie = (props) => {
  const [cookies, setCookie] = useCookies(['name']);
  const [count, setCount] = useState(true);

  function onChange() {
    let date = new Date();
    date.setDate(date.getDate() + 30);
    setCookie('notification', true, { path: '/', expires: date });
    setCount(false);
  }

  if (!cookies.notification) {
    return (
      <div className={count ? b({active: true}) : b()}>
        <div className={b('inner')}>
          <div className={b('close')} onClick={onChange} name={cookies.name}>
            <CloseSVG />
          </div>
          <div className={b('content')}>
            {props.children}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Cookie;
