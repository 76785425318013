import React from 'react';
import block from 'bem-cn';
import Title from '../Title';
import CustomersItem from '../CustomersItem';
import More from '../More';
import Questions from '../Questions';
import './Customers.styl';

const b = block('customers');

const Customers = (props) => {
  const { color, title, data, more } = props;

  return (
    <div className={b()}>
      <Title color={color}>{title}</Title>

      <div className={b('content')}>
        {data.map(item => (
          <CustomersItem
            key={item.id}
            data={item}
            link={props.link}
            theme={props.theme}
          />
        ))}
      </div>
      {
        more ? (
          <More
            url='/customers'
            name='Все Клиенты'
          />
        ) : ''
      }
      <Questions />
    </div>
  );
};

export default Customers;
