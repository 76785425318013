import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import './index.styl';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import Services from './pages/Services';
import About from './pages/About';
import Customers from './pages/Customers';
import Reviews from './pages/Reviews';
import Licenses from './pages/Licenses';
import Contacts from './pages/Contacts';
import None from './pages/None';
import Text from './pages/Text';

const app = (
  <CookiesProvider>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route path={`/services/:serviceId`}>
            <Text />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/customers">
            <Customers />
          </Route>
          <Route path="/reviews">
            <Reviews />
          </Route>
          <Route path="/licenses">
            <Licenses />
          </Route>
          <Route path="/contacts">
            <Contacts />
          </Route>
          <Route>
            <None />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  </CookiesProvider>
);

ReactDOM.render(app, document.getElementById('root'));
