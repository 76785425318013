import React from 'react';
import block from 'bem-cn';
import linkSvg from './link.svg';
import './CustomersItem.styl';

const b = block('customers-item');

const CustomersItem = (props) => {
  const { id, photo, logo, name, description, address } = props.data;

  function createMarkup(data) {
    return { __html: data }
  }

  return (
    <div key={id} className={b({ theme: props.theme })}>
      <div className={b('photo')} style={{backgroundImage: `url(${photo.url})`}}></div>
      {
        logo.url !== '' ?
          <img className={b('logo')} src={logo.url} alt='' />
        : ''
      }
      <p className={b('name')} dangerouslySetInnerHTML={createMarkup(name)} />
      <p className={b('desc')} dangerouslySetInnerHTML={createMarkup(description)} />
      {props.link ?
        <p className={b('url')}>
          <a to="/" href={address.url} target="_blank" rel="noopener noreferrer">{address.name}</a><span><img src={linkSvg} alt="" /></span>
        </p>
        : ''
      }
    </div>
  );
};

export default CustomersItem;
