import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Responsive from 'react-responsive';
import block from 'bem-cn';
import { YMInitializer } from 'react-yandex-metrika';
import Header from '../Header';
import Popup from '../Popup';
import Menu from '../Menu';
import Phone from '../Phone';
import Footer from '../Footer';
import Cookie from '../Cookie';
import json from '../../data.json';
import './Layout.styl';

const axios = require ('axios');

const b = block('layout');
const index = json.index;

const Mobile = props => <Responsive {...props} maxWidth={767} />;

function Layout(props) {
  const [burger, setBurger] = useState(false);
  const [header, setHeader] = useState(false);
  const [showCookie, setShowCookie] = useState(true);

  let location = useLocation();

  // useEffect(() => {
  //   axios.post('https://sfera-backend.now.sh/telegram', { title: props.title })
  //   // axios.post('http://localhost:4000/api/v1', data)
  //   .then(response => {
  //     console.log(response);
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
  // }, [location.pathname]);

  const UNSAFE_componentWillMount = () => {
    window.addEventListener('scroll', () => {
      setHeader(window.scrollY >= 1);
    });

    window.addEventListener('resize', () => {
      if(window.innerWidth >= 768) {
        setBurger(false);
      }
    });
  }

  const toggleMenu = () => {
    setBurger(!burger);
  }

  const updateData = (value) => {
    setShowCookie(value);
  }

    const { children } = props;

  return (
    <div className={burger ? b({ fixed: true }) : b() && header ? b({ header_fixed: true }) : b()} >
      <Header
        menu={index.menu}
        contacts={index.contacts}
        burger={burger}
        header={header}
        onChange={() => toggleMenu()}
      />

      { children }

      <Footer menu={index.menu} contacts={index.contacts} />

      <Mobile>
        <Popup open={burger} onChange={() => toggleMenu()}>
          <Menu items={index.menu} mods="burger" />
          <Phone data={index.contacts} mods="burger" />
        </Popup>
      </Mobile>

      <Cookie
        updateData={updateData}
      >
        Этот сайт использует cookie для хранения данных. Продолжая использовать сайт, вы даете свое согласие на работу с этими файлами.
      </Cookie>

      <YMInitializer accounts={[57626434]} options={{webvisor: true}} version="2" />
    </div>
  )
}

export default Layout;
