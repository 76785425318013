import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import './More.styl';

const b = block('more');

const More = (props) => {
  return (
    <div className={b()}>
    {
      props.url ?
        <Link to={props.url} className={b('link')}>
            {props.name}
        </Link>
      :
        <div className={b('link')} onClick={props.onChange}>{props.name}</div>
    }
    </div>
  );
};

export default More;
