import React from 'react';
import block from 'bem-cn';
import './LicensesItem.styl';

const b = block('licenses-item');

function createMarkup(data) {
  return { __html: data }
}

const LicensesItem = (props) => {
  const { name, image, photo } = props.data;

  return (
    <div className={b()}>
      <div className={b('content')} onClick={() => {props.updateData(process.env.PUBLIC_URL + photo)}}>
        <div className={b('image')}>
          <img src={process.env.PUBLIC_URL + image} alt="" />
        </div>
        <div className={b('name')}>
          <p dangerouslySetInnerHTML={createMarkup(name)} />
        </div>
      </div>
    </div>
  );
};

export default LicensesItem;
