import React from 'react';
import { useParams } from 'react-router-dom';
import block from 'bem-cn';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Title from '../../components/Title';
import Waves from '../../components/Waves';
import Next from '../../components/Next';
import Questions from '../../components/Questions';
import json from '../../data.json';
import './Text.styl';

const b = block('text');
const services = json.services;

const TextPage = (props) => {
  let { serviceId } = useParams();
  const breadcrumbs = [
    {
      "id": "1",
      "name": "Главная",
      "url": "/"
    },
    {
      "id": "2",
      "name": "Услуги",
      "url": "/services"
    }
  ]

  function createMarkup(data) {
    return { __html: data }
  }

  var content =  services.items.filter(function(item) {
    return item.url === `/services/${serviceId}`;
  });

  // console.log(content[0]);

  return (
    <Layout className={b()} title={content[0].name}>
      <Helmet>
        <title>{`${content[0].name} ${services.pageTitleInside}`}</title>
      </Helmet>

      <div className={b('page').mix('content')}>
        <Breadcrumbs data={breadcrumbs} />
        <Title>{content[0].name}</Title>
        <Waves
          id='1'
          colorUp={'#ffffff'}
          colorDown={'#f5f5f5'}
          composite1={'screen'}
          composite2={'darken'}
        />

        <div className={b('page').mix('text')}>
          <p dangerouslySetInnerHTML={createMarkup(content[0].description)} />
        </div>

        <Waves
          id='2'
          colorUp={'#f5f5f5'}
          colorDown={'#ffffff'}
          composite1={'screen'}
          composite2={'source-atop'}
        />
        <Next
          name="Далее"
          url={content[0].nextUrl}
        />
        <Questions />
      </div>
    </Layout>
  );
};

export default TextPage;
