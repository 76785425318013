import React, { Component } from 'react';
import block from 'bem-cn';
import Title from '../Title';
import Card from '../Card';
import More from '../More';
import './Services.styl';

const b = block('services');

class Services extends Component {

  render() {
    const { color, mods, title, data, more } = this.props;

    return (
      <div className={b({ theme: mods })}>
        <Title color={color}>{title}</Title>

        <div className={b('content')}>
          {data.map(item => (
            <div key={item.id} className={b('item')}>
              <Card
                url={item.url}
                name={item.name}
                icon={item.icon}
                iconMobile={item.iconMobile}
                mods={`services-${mods}`}
              />
            </div>
          ))}
        </div>
        {
          more ? (
            <More
              url='/services'
              name='Все Услуги'
            />
          ) : ''
        }
      </div>
    )
  }
}

export default Services;
