import React from 'react';
import block from 'bem-cn';
import Title from '../Title';
import Card from '../Card';
import './Advantages.styl';

const b = block('advantages');

const Advantages = (props) => {
  const { color, title, data } = props;

  return (
    <div className={b()}>
      <Title color={color}>{title}</Title>

      <div className={b('content')}>
        {data.map(item => (
          <div key={item.id} className={b('item')}>
            <Card
              name={item.name}
              icon={item.icon}
              iconMobile={item.iconMobile}
              mods={`advantages`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advantages;
