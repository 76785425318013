import React from 'react';
import block from 'bem-cn';
import Responsive from 'react-responsive';
import Menu from '../Menu';
import Logo from '../Logo';
import Phone from '../Phone';
import Up from '../Up';
import Copyright from '../Copyright';
import './Footer.styl';
const b = block('footer');

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

const Footer = (props) => {
  return (
    <div className={b()}>
      <Default>
        <div className={b('left')}>
          <Menu items={props.menu} mods='footer' />
          <div className={b('policy')}>
            <a href='/policy.pdf' target='_blank'>Политика конфиденциальности</a>
          </div>
          <Logo mods='white' />
        </div>
      </Default>

      <div className={b('right')}>
        <Phone data={props.contacts} mods='footer' />
      </div>

      <div className={b('bottom')}>
        <Mobile>
          <Up
            url='#up'
            name='Наверх'
          />
        </Mobile>
        <Copyright />
      </div>
    </div>
  );
};

export default Footer;
