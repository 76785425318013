import React from 'react';
import block from 'bem-cn';
import { ReactComponent as CloseSVG } from './close.svg';
import './Modal.styl';

const b = block('modal');

const Modal = (props) => {
  return (
    <div className={props.open ? b({active: true}) : b()}>
      <div className={b('blackout')} onClick={props.closeModal}></div>
      <div className={b('inner')}>
        <span className={b('title')}>{props.title}</span>
        <div className={b('close')} onClick={props.closeModal}>
          <CloseSVG />
        </div>
        <div className={b('content')}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
