import React from 'react';
import block from 'bem-cn';
import './Title.styl';

const b = block('title');

function createMarkup(data) {
  return { __html: data }
};

function Title(props) {
  return (
    <h2
      className={props.color ? b({ color: props.color }) : b()}
      dangerouslySetInnerHTML={createMarkup(props.children)}
    />
  );
}

export default Title;
