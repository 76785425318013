import React from 'react';
import block from 'bem-cn';
import './Copyright.styl';

const b = block('copyright');

const Copyright = () => {
  return (
    <div className={b()}>
      <span>2013 – 2020</span>
    </div>
  );
};

export default Copyright;
