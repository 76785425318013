import React from 'react';
import { Link } from 'react-router-dom';
import block from 'bem-cn';
import './Breadcrumbs.styl';

const b = block('breadcrumbs');

const Breadcrumbs = (props) => {
  return (
    <div className={b()}>
      {props.data.map(item => (
        <span key={item.id}>
          <Link
            to={item.url}
          >
            {item.name}
          </Link>
          <span> / </span>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
